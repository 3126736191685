<template>
  <div>
    <b-row
      v-if="isValidToken && tokenChecked"
    >
      <b-col
        cols="12"
        lg="5"
      >
        <b-card
          v-if="offer.isSigned === 1"
          title=""
        >
          <b-row>
            <b-col cols="12">
              Geachte {{ offer.customerRelation.gender === 'm'? 'heer':'mevrouw' }} {{ offer.customerRelation.middleName?offer.customerRelation.middleName:'' }} {{ offer.customerRelation.lastName }},
              <br><br>
              Hartelijk dank voor uw bestelling van {{ offer.product.name }}. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw bestelling.
              <br><br>
              Met vriendelijke groet,
              <br><br>
              {{ offer.product.supplier.name }}
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-if="offer.isSigned === 0"
          title=""
        >
          <b-row>
            <b-col cols="12">
              Geachte {{ offer.customerRelation.gender === 'm'? 'heer':'mevrouw' }} {{ offer.customerRelation.middleName?offer.customerRelation.middleName:'' }} {{ offer.customerRelation.lastName }},
              <br><br>
              Hartelijk dank voor je offerte aanvraag met kenmerk {{ offer.id }}. Wij zijn ervan overtuigd dat wij je namens {{ offer.product.supplier.name }} een aantrekkelijk aanbod doen met {{ offer.product.name }}.
              <br><br>
              Met vriendelijke groet,
              <br><br>
              {{ offer.product.supplier.name }}
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-if="offer.isSigned === 0"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(acceptOffer)"
            >
              <validation-provider
                #default="validationContext"
                name="iban"
                rules="required|iban"
              >
                <b-form-group
                  label="IBAN rekeningnummer"
                  label-for="iban"
                >
                  <b-form-input
                    v-model="offer.customerRelation.bankAccountNumber"
                    name="iban"
                    :state="getValidationState(validationContext)"
                    disabled="disabled"
                    trim
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationContext"
                name="ibanHolder"
                rules="required"
              >
                <b-form-group
                  label="Tenaamstelling"
                  label-for="ibanHolder"
                >
                  <b-form-input
                    id="holderName"
                    v-model="offer.customerRelation.debtorName"
                    :state="getValidationState(validationContext)"
                    disabled="disabled"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form>
          </validation-observer>
          <b-card-text v-if="!offer.product.requireMollieMandateSign">
            <b-card-header class="m-0 p-0">
              <b-card-title style="color: #6e6b7b;font-size: 0.857rem;font-weight: normal;margin-bottom:5px">
                Handtekening:
              </b-card-title>
            </b-card-header>
            <b-card-body class="m-0 p-0">
              <VueSignaturePad
                ref="signaturePad"
                :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
                :class="this.signatureCheck && !this.signatureCheck.isEmpty ? 'signature':'signature invalid'"
                width="340px"
                height="150px"
              />
              <div class="mt-2 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="undo()"
                ><span>Opnieuw</span></b-button>
              </div>
            </b-card-body>
          </b-card-text>
        </b-card>
        <b-card
          v-if="offer && offer.product && offer.product.files"
          title="Voorwaarden en informatie documenten"
        >
          <b-row>
            <b-col
              v-for="(item, index) in offer.product.files"
              :key="index"
              cols="12"
            >
              <b-link
                :href="item.fileURL"
                target="_blank"
              >
                <i
                  class="far fa-file-pdf"
                  style="height:16px;padding-right:5px;color:#ea5455"
                />
                <span>{{ item.description }}</span>
              </b-link>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-if="offer.isSigned === 0"
        >
          <b-card-text
            class="mb-1"
          >
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormAgreementObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(acceptOffer)"
              >
                <validation-provider
                  #default="validationContext"
                  name="agreementAccepted"
                  rules="required"
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-checkbox
                      v-model="offer.accepted"
                      name="agreementAccepted"
                      :state="getValidationState(validationContext)"
                      :value="1"
                      :unchecked-value="0"
                      required
                    >
                      Ik heb de algemene voorwaarden begrepen en ik ga akkoord met de algemene voorwaarden.
                    </b-form-checkbox>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </validation-observer>
          </b-card-text>
          <b-card-text>
            <b-button
              variant="primary"
              class="mr-1"
              :disabled="showSignAgreementLoader"
              style="border-radius:0.358rem;"
              @click="acceptOffer"
            >
              <b-spinner
                v-if="showSignAgreementLoader"
                small
                class="mr-1"
              />
              <span class="align-middle">Accepteren {{ offer.product.requireMollieMandateSign ? ' met 1 cent betaling' : '' }}</span>

            </b-button>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="7"
      >
        <b-card
          no-body
        >
          <b-card-header class="pb-3">
            <b-row>
              <b-col
                v-if="pdf"
                cols="12"
              >
                <label>{{ page }} / {{ pageCount }}</label>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="ml-1"
                  :disabled="pageCount === '1' || page === 1"
                  @click="page = page - 1"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="ml-1"
                  :disabled="pageCount === page || pageCount === 1"
                  @click="page = page + 1"
                >
                  <feather-icon icon="ArrowRightIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="ml-2"
                  variant="secondary"
                  @click="$refs.pdfViewer.print(300)"
                >
                  <feather-icon icon="PrinterIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <pdf
              v-if="pdf"
              ref="pdfViewer"
              :src="pdf"
              :page="page"
              dpi="300"
              @num-pages="pageCount = $event"
              @page-loaded="showOverlay = false"
              @error="error"
            />
            <b-overlay
              :show="showOverlay"
              rounded="sm"
            >
              <b-row>
                <b-col
                  cols="12"
                  style="height:200px"
                />
              </b-row>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="!isValidToken && tokenChecked">
      <b-col cols="12">
        <b-card
          title="Invalide token"
        >
          <b-card-text>
           De link waarmee je deze pagina probeert te bezoeken is niet meer geldig en/of verlopen. Neem contact op met de klantenservice van HEM voor meer informatie. Op werkdagen te bereiken op 020 225 99 34 van 09:00 tot 17:00 uur.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BCardHeader,
  BCardBody,
  BCardText,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback, BForm, BFormGroup, BCardTitle, BOverlay, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import pdf from 'vue-pdf'
import offerStoreModule from '@/views/pages/offer/offerStoreModule'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, iban } from '@core/utils/validations/validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormCheckbox,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BCardTitle,
    BSpinner,
    ValidationObserver,
    pdf,
    ValidationProvider,
    BFormGroup,
    BOverlay,
  },
  data() {
    return {
      tokenChecked: false,
      isValidToken: false,
      showSignAgreementLoader: false,
      token: '',
      offer: {},
      url: null,
      showOverlay: true,
      page: 1,
      pageCount: 0,
      signatureCheck: true,
      pdf: null,
      required,
      iban,
    }
  },
  mounted() {
    this.fetchOrder()
  },
  methods: {
    fetchOrder() {
      this.token = this.$route.params.id
      store.dispatch('app-offer/fetchOffer', {
        token: this.token,
      }).then(async response => {
        this.getOfferPDF()
        this.tokenChecked = true
        this.isValidToken = true
        this.offer = response.data
      }).catch(() => {
        this.tokenChecked = true
        this.isValidToken = false
      })
    },
    async acceptOffer() {
      if (!this.offer.product.requireMollieMandateSign) {
        this.signatureCheck = this.validateSignature()
      }
      const orderValidators = [this.$refs.refFormObserver.validate(), this.$refs.refFormAgreementObserver.validate()]
      await Promise.all(orderValidators).then(valid => {
        if (this.offer.product.requireMollieMandateSign) {
          if (valid.includes(false)) {
            return
          }
          this.showSignAgreementLoader = true
          window.location.href = `https://api.hem.nl/v1/customer/offer/${this.token}/acceptwithmollie`
        } else if (!valid.includes(false) && !this.signatureCheck.isEmpty) {
          this.showSignAgreementLoader = true
          store.dispatch('app-offer/acceptOffer', {
            ibanHolder: this.offer.ibanHolder,
            iban: this.offer.iban,
            signatureSVG: this.signatureCheck.data,
            token: this.token,
          })
            .then(resp => {
              this.showSignAgreementLoader = false
              this.fetchOrder()
              this.getOfferPDF()

              if (resp.data.success === true && resp.data.requireMandate === true) {
                let timerInterval
                this.$swal({
                  icon: 'success',
                  title: 'SEPA Mandaat',
                  html: '<p>Je hebt zojuist een overeenkomst getekend voor de levering van stroom en/of gas bij HEM en daar zijn wij ontzettend blij mee. Welkom! </p>\n'
                      + '    <p>Omdat je hebt gekozen voor automatisch incasso hebben wij een machtiging nodig om je maandelijks te kunnen incasseren. </p>\n'
                      + '    <p>Met de keuze om middels een automatisch incasso te betalen machtig je HEM – dit is de handelsnaam van Allround Hollands Energie B.V. - om doorlopende incasso-opdrachten te versturen naar jouw bank, via Mollie. Hiervoor hanteren wij de standaard Europese incasso – SEPA en doen dit via onze payment provider Mollie. </p>'
                      + '<p> We sturen je binnen 15 seconden door naar de beveiligde omgeving van Mollie, hier kunt u het SEPA-Mandaat door middel van een 1 cent betaling afgeven</p>',
                  timer: 15000,
                  timerProgressBar: true,
                  didOpen: () => {
                    this.$swal.showLoading()
                    const b = this.$swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                      b.textContent = this.$swal.getTimerLeft()
                    }, 100)
                  },
                  willClose: () => {
                    clearInterval(timerInterval)
                  },
                }).then(() => {
                  window.location.href = resp.data.mandateURL
                })
              }
            })
            .catch(() => {
              this.showSignAgreementLoader = false
              this.$swal({
                icon: 'error',
                title: 'Voorstel accepteren',
                text: 'Er is een fout opgetreden bij het accepteren van het voorstel. Probeer later het nog een keer.',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            })
        }
      })
    },
    getOfferPDF() {
      this.showOverlay = true
      store.dispatch('app-offer/fetchOfferPDF', {
        token: this.token,
      }).then(async response => {
        this.showOverlay = false
        this.pdf = { data: response.data }
      }).catch(() => {
        this.showOverlay = false
        this.$swal({
          icon: 'error',
          title: 'Voorstel PDF',
          text: 'Er is een fout opgetreden bij het ophalen van de pdf. Probeer later het nog een keer.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      })
    },
    error() {
      this.showOverlay = false
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    validateSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/svg+xml')
      return { isEmpty, data }
    },
  },
  setup() {
    const OFFER_STORE_MODULE_NAME = 'app-offer'

    // Register module
    if (!store.hasModule(OFFER_STORE_MODULE_NAME)) store.registerModule(OFFER_STORE_MODULE_NAME, offerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OFFER_STORE_MODULE_NAME)) store.unregisterModule(OFFER_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.signature {
  border: solid 2px #000000;
  border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.signature.invalid {
  border: solid 2px #ea5455;
}

.invalid{
  .card-title{
    color: #ea5455
  }
}

.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
